
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onMounted, reactive, ref, render, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import XQJZDetail from '@/views/xqjz/XQJZDetail.vue'
import { getXqjzList, addBlackUser, delBlackUser, exportXqjContract, XqjzListQueryParams, invalidXqjz } from '@/api/xqjz'
import { formatDate, formatTime, getAreaListOfGx } from '@/utils/common'
import { ElMessage, ElMessageBox } from 'element-plus'
import { AxiosResponse } from 'axios'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'

enum TabPane {
  ALL = 'all',
  AVALIABLE = 'availabel',
  BLACK_LIST = 'blackList',
  RED_LIST = 'redList'
}
 
export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    XQJZDetail
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<XqjzListQueryParams>({})
    const tableData = ref<AnyArray>([])
    const selectedPan = ref<TabPane>(TabPane.ALL)
    const badgeValues = reactive<AnyObject>({})
		const gxAreaList = reactive<AnyArray>([])
    const selectedRowIDs = ref<number[]>([])
    const selectCount = computed(() => selectedRowIDs.value.length)
		
    const getTableData = async () => {
      const res = await getXqjzList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    const tableRowClassName = ({ row , rowIndex }: {row: AnyObject, rowIndex: number}) => {
      if(selectedRowIDs.value.indexOf(row.id) >= 0)
        return 'current-row' //高亮选中行，这个是 element-ui 自带CSS类名
      else if (row.isRedUser === 1)
        return 'xqjm-red-list-row'
      return ''
    }

    watch(selectedPan, val => {
      searchData.isRedUser = undefined
      searchData.isBlackUser = undefined
      if (val === TabPane.AVALIABLE) {
        searchData.isBlackUser = 2
        searchData.isRedUser = 2
      } else if (val === TabPane.BLACK_LIST) {
        searchData.isBlackUser = 1
      } else if (val === TabPane.RED_LIST) {
        searchData.isRedUser = 1
      }
      tableData.value = []
      selectedRowIDs.value = []
      getTableData()
    })

    const onSelectionChange = (selection: any[]) => (selectedRowIDs.value = selection.map((item: AnyObject) => item.id))

    const xqjzDetailRef = ref()
    const exportRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        label: '生产机主名',
        type: 'input',
        placeholder: '请输入生产机主名',
        clearable: true,
        model: toRef(searchData, 'name')
      }, {
        label: '电话',
        type: 'input',
        placeholder: '请输入电话',
        clearable: true,
        model: toRef(searchData, 'phone')
      }, {
        label: '身份证',
        type: 'input',
        placeholder: '请输入身份证',
        clearable: true,
        model: toRef(searchData, 'idCard')
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        model: toRef(searchData, 'areaCode')
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }, {
        label: () => {
          if (selectedPan.value === TabPane.AVALIABLE || selectedPan.value === TabPane.RED_LIST) return '加入黑名单'
          return '移除黑名单'
        },
        show: () => selectedPan.value === TabPane.AVALIABLE || selectedPan.value === TabPane.BLACK_LIST || selectedPan.value === TabPane.RED_LIST,
        icon: 'el-icon-refresh-right',
        onClick: async () => {
          const xqjcIdList = selectedRowIDs.value;
          let msg = selectedPan.value === TabPane.AVALIABLE || selectedPan.value === TabPane.RED_LIST ? '添加黑名单' : '移除黑名单'

          if (xqjcIdList.length === 0) {
            ElMessage.warning?.(`请选择需要${msg}的生产机主`)
            return
          }

          const requestMethod = selectedPan.value === TabPane.AVALIABLE || selectedPan.value === TabPane.RED_LIST ? addBlackUser: delBlackUser
          
          await ElMessageBox.confirm(`是否确认${msg}？`, '提示', { type: 'warning' })
          
          const reqList: Promise<AxiosResponse>[] = []
          xqjcIdList.forEach((id: number) => reqList.push(requestMethod(id)))
          const resList = await Promise.all(reqList)
          ElMessage.success?.(`${msg}成功`)
          getTableData()
        }
      }, 
      // {
      //   label: '移除红名单',
      //   show: () => false,
      //   icon: 'el-icon-refresh-right',
      //   onClick: async () => {
      //     const xqjcIdList = selectedRowIDs.value

      //     if (xqjcIdList.length === 0) {
      //       ElMessage.warning?.(`请选择需要移除红名单的生产机主`)
      //       return
      //     }

      //     await ElMessageBox.confirm(`是否确认移除红名单？`, '提示', { type: 'warning' })
          
      //     const reqList: Promise<AxiosResponse>[] = []
      //     await delRedUser(xqjcIdList)
      //     ElMessage.success?.(`移除红名单成功`)
      //     getTableData()
      //   }
      // }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        label: '多选',
        type: 'selection',
        width: '55px',
        align: 'center',
        reserveSelection: true,
      }, {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'name',
        label: '生产机主',
        minWidth: '140px',
      }, {
        prop: 'idcard',
        label: '身份证号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'phone',
        label: '电话号码',
        minWidth: '160px',
        preventFormat: true
      },{
        prop: 'address',
        label: '生产地址',
        minWidth: '400px',
      }, {
        type: 'render',
        prop: 'createdtime',
        label: '注册时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        prop: 'machinecount',
        label: '旋切机数量',
        width: '120px',
        align: 'center',
      }, {
        prop: 'recommendcode',
        label: '推广码',
        minWidth: '100px',
        align: 'center'
      }, {
        prop: 'noSendPicSum',
        label: '未拍照发车次数',
        minWidth: '130px',
        align: 'center'
      }, {
        type: 'render',
        prop: 'licenseNo',
        label: '是否办理营业执照',
        minWidth: '150px',
        align: 'center',
        render: scope => scope.row.licenseNo == null? '否': '是'
      }, {
        prop: 'accountSum',
        label: '下挂账户数',
        minWidth: '150px',
        align: 'center'
      }, {
        prop: 'noLicenseAccountSum',
        label: '未办营业执照账户数',
        minWidth: '150px',
        align: 'center'
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          {
            label: '详情',
            onClick: scope => xqjzDetailRef.value.show(scope.row.id)
          }, {
            label: '合同',
            onClick: scope => {
              exportXqjContract(scope.row.id)
            }
          }, {
            label: '删除',
            type: 'danger',
            onClick: async scope => {
              await ElMessageBox.confirm('是否确认删除该旋切机主？', '提示', { type: 'warning' })
              await invalidXqjz(scope.row.id)
              ElMessage.success('删除成功')
              await getTableData()
            }
          }
        ]
      }
    ])

    return {
      getTableData,
      onSelectionChange,
      tableRowClassName,
      searchInputs,
      selectedPan,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      xqjzDetailRef,
      exportRef
    }
  }
})
